import React from "react";

export const Section = props => {
  return (
    <section className="hero">
      <div className="hero-body">
        <div className="container">
          <h1 className="title">{props.Header}</h1>
          <h2 className="subtitle">{props.Subtitle}</h2>
          {props.Body}
        </div>
      </div>
    </section>
  );
};
