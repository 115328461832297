import React from "react";
import { Section } from "./Section";
export const Support = props => {
  return (
    <Section
      Header="Support"
      Subtitle="Email support@robocoin.co with any issues"
    />
  );
};
