import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./global.scss";
import { Docs } from "./components/Docs";
import { Support } from "./components/Support";
import { Privacy } from "./components/Privacy";
import { Success } from "./components/Success";
import { Error } from "./components/Error";
import robocoinLogo from "./assets/icon.svg";
import { Section } from "./components/Section";
import exampleImage from "./assets/robocoin_example.png";

function App() {
  return (
    <Router>
      <div className="container">
        <nav className="navbar" role="navigation" aria-label="main navigation">
          <a className="navbar-item logo-item" href="/">
            <img src={robocoinLogo} width="28" height="28" alt="" />
          </a>
          <div className="navbar-brand">
            <a
              role="button"
              className="navbar-burger burger"
              aria-label="menu"
              aria-expanded="false"
              data-target="navbarBasicExample"
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>

          <div id="navbarBasicExample" className="navbar-menu">
            <div className="navbar-start">
              <a className="navbar-item" href="/docs">
                Documentation
              </a>
              <a className="navbar-item" href="/privacy">
                Privacy
              </a>
              <a className="navbar-item" href="/support">
                Support
              </a>
            </div>

            <div className="navbar-end"></div>
          </div>
        </nav>

        {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/privacy">
            <Privacy />
          </Route>
          <Route path="/support">
            <Support />
          </Route>
          <Route path="/docs">
            <Docs />
          </Route>
          <Route path="/success">
            <Success />
          </Route>
          <Route path="/error">
            <Error />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

var body = (
  <>
    <p className="description-text">
      If you are like us, you check crypto currency prices all day every day.
      Robocoin allows users to check crypto currency prices instantly right
      within your Slack instance.
      <br />
      <br />
      We have access to thousands of different crypto currencies. Check Market
      Cap, 24 Hour Volume and 24 Hour Change all from right within your Slack
      team!!
    </p>
    <a href="https://slack.com/oauth/v2/authorize?client_id=278025969120.911435115232&scope=commands&redirect_uri=https://robocoin.co/api/callback">
      <img
        alt="Add to Slack"
        height="40"
        width="139"
        src="https://platform.slack-edge.com/img/add_to_slack.png"
        srcset="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
      />
    </a>
    <br />
    <br />
    <p>
      <b>Help Support the Bot!</b>
      <br />
      eth 0x201965EFcd95c1893E2022a361F44834a6eCC0d7
    </p>
  </>
);

var howitWorksbody = (
  <>
    <p className="description-text">
      Its as easy as typing! Seriously, just type "/price TICKER" into any
      channel and RoboCoin will give you up to the minute ticker data in
      seconds!
    </p>
  </>
);

const Home = props => {
  return (
    <div className="">
      <div className="columns">
        <div className="column">
          <img src={robocoinLogo} alt="" />
        </div>
        <div className="column">
          <Section
            Header="Robocoin"
            Subtitle="Crypto currency price checker"
            Body={body}
          />
        </div>
      </div>
      <div className="column">
        <Section
          Header="How It Works"
          Subtitle="RoboCoin adds a /price command to your Slack workspace"
          Body={howitWorksbody}
        />
      </div>
      <div className="column">
        <img src={exampleImage} alt="" />
      </div>
    </div>
  );
};

export default App;
