import React from "react";
import { Section } from "./Section";
import robocoinLogo from "../assets/icon.svg";

export const Error = props => {
  return (
    <div className="columns">
      <div className="column is-centered">
        <Section
          Header="Oh noooo!"
          Subtitle="Crap there was an error! Sad robot"
        />
      </div>
      <div className="column">
        <img src={robocoinLogo} alt="" />
      </div>
    </div>
  );
};
